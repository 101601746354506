import React from "react";
import Img from "gatsby-image";
import BackgroundImg from "gatsby-background-image";

const Images = ({ input }) => {
  // const [imgStyle, setImgStyle] = useState()
  // console.log(input.items);
  // const className = input.images.sizes.aspectRatio < 1 ? "col-md-4" : "col-md-12"
  // useEffect(() => {
  //   // if()
  // }, [])

  const { style } = input.primary
  const length = input.items.length
  
  const gridClass = style === "inline" ? "autoW" : ""
  const gridClassLength = style !== "inline" 
    ? length === 4 
      ? "has-four" 
      : ""
    : ""
  
  // console.log("length",length)
  // console.log("style",style)
  if(style === "grid"){
    console.log(input.items[0])
  }
  return (
    <div 
    className={"module images pt-xl pr-xxl pl-xxl "+gridClass+" "+gridClassLength}
    style={input.primary.color 
      ? {backgroundColor: input.primary.color} 
      : {backgroundColor: "transparent"}
    }
    >
      {/* <pre>{JSON.stringify(length)}</pre>
      <pre>{JSON.stringify(style)}</pre> */}
      {/* <div className="sepFL"></div> */}
      {style === "inline" &&
        <div className="images-inline">
          
          {input.items.map( ({image, legende}, i) => (
            
            <div className={"inner "+(legende.html ? "has-legende" : "")} key={i}>
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                Tag="figure"
                style={{ 
                  height: 'calc(100vh - 240px)',
                  width: `calc((100vh - 240px) * ${image.localFile.childImageSharp.fluid.aspectRatio})`
                  
                }}
                imgStyle={{ objectFit: 'contain' }}
              />
              
              {legende &&
                <div className="texte legende"
                  dangerouslySetInnerHTML={{
                      __html: legende.html,
                  }}
                ></div> 
              }
              {/* <div className="length">{length}</div> */}
            </div>
            
          ))}
        </div>
      }
      {style === "grid" &&
        <div className={"images-grid "+(input.items[0].legende && input.items[0].legende.html ? "has-legende" : "")}>
          {/* <pre>grid</pre> */}
          {input.items.map( ({image, legende}, i) => (
            <div className="images-grid__panel" key={i}>
              <div className="inner">
      
                <BackgroundImg
                  fluid={image.localFile.childImageSharp.fluid}
                  Tag="figure"
                  style={{
                    // height: `calc(100% * ${image.localFile.childImageSharp.fluid.aspectRatio})`
                    // backgroundSize: 'contain',
                    // backgroundPosition: 'left center'
                  }}
                  // imgStyle={{ objectFit: 'contain' }}
                  data-ratio={image.localFile.childImageSharp.fluid.aspectRatio}
                />

                {legende &&
                  <div className="texte legende"
                    dangerouslySetInnerHTML={{
                        __html: legende.html,
                    }}
                  ></div>
                }
              </div>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default Images;
