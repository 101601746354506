import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { WrapperContext } from "./Layout"

const SectionContact = () => {
    const { contactFr, contactEn } = useStaticQuery(query)

    const _WrapperContext = useContext(WrapperContext)
    const { locale, localized } = _WrapperContext

    const contact = locale === "fr-fr" ? contactFr : contactEn

    return (
        <section className="contact" data-title="contact">
            <h2 className="section-title xs-only">{localized["contact"]}</h2>
            <div className="inner inner-padded">
                <div
                    className="texte fXL"
                    dangerouslySetInnerHTML={{
                        __html: contact.data.texte.html,
                    }}
                ></div>
            </div>
        </section>
    )
}

export default SectionContact

const query = graphql`
    query {
        contactFr: prismicContact(lang: { eq: "fr-fr" }) {
            data {
                title {
                    text
                }
                texte {
                    html
                }
            }
        }
        contactEn: prismicContact(lang: { eq: "en-gb" }) {
            data {
                title {
                    text
                }
                texte {
                    html
                }
            }
        }
    }
`
