import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { WrapperContext } from "./Layout"
import Footer from "./Footer"
import InstagramCard from "./ui/InstagramCard"

const SectionHome = () => {
    const _WrapperContext = useContext(WrapperContext)
    const { locale } = _WrapperContext
    // console.log(options)
    const { homeFr, homeEn } = useStaticQuery(query)
    const home = locale === "fr-fr" ? homeFr : homeEn

    // const getPathByUrl = url => (
    //     url.substring(url.lastIndexOf('/') + 1)
    // )
    // console.log(home.data.instagram )
    return (
        <section className="home" data-title={home.data.title.text}>
            <div className="inner inner-padded">
                <div
                    className="texte fL"
                    dangerouslySetInnerHTML={{
                        __html: home.data.texte.html,
                    }}
                ></div>
                <Footer />
                {home.data.instagram && <InstagramCard username={home.data.instagram} />}
            </div>
        </section>
    )
}

export default SectionHome

const query = graphql`
    query {
        homeFr: prismicHomepage(lang: { eq: "fr-fr" }) {
            data {
                title {
                    text
                }
                texte {
                    html
                }
                instagram
            }
        }
        homeEn: prismicHomepage(lang: { eq: "en-gb" }) {
            data {
                title {
                    text
                }
                texte {
                    html
                }
                instagram
            }
        }
    }
`
