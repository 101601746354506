export function fileNameByUrl(url) {
    const decoded = decodeURIComponent(url)
    return decoded.substring(decoded.lastIndexOf("/") + 1)
}

export function isElementInViewport(el) {
    // const wrapperRect = wrapper.getBoundingClientRect();
    const bounding = el.getBoundingClientRect()
    // console.log(bounding.left)
    return (
        bounding.left < window.innerWidth / 2
        // && bounding.left < window.innerWidth
    )
}

// export function throttle(delay, func) {
//   let inThrottle
//   return function() {
//     const args = arguments
//     const context = this
//     if (!inThrottle) {
//       func.apply(context, args)
//       inThrottle = true
//       setTimeout(() => inThrottle = false, delay)
//     }
//   }
// }

export function is_touch_device() {
    return "ontouchstart" in window
}
