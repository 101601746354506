import React from "react";

const Texte = ({ input }) => {
  // console.log(input)
  return (
    <div className="module texte pt-xl pr-l pl-l autoW">
      {/* <div className="sepFL"></div> */}
      {input.primary.chapo &&
        <div className="texte chapo"
          dangerouslySetInnerHTML={{
              __html: input.primary.chapo.html,
          }}
        ></div>
      }

      {input.primary.texte1 &&
        <div className="texte "
          dangerouslySetInnerHTML={{
              __html: input.primary.texte1.html,
          }}
        ></div>
      }
    </div>
  );
};

export default Texte;
