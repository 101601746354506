import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { WrapperContext } from "./Layout"

const SectionAbout = () => {
    const { aboutFr, aboutEn } = useStaticQuery(query)

    const _WrapperContext = useContext(WrapperContext)
    const { locale, localized } = _WrapperContext

    const about = locale === "fr-fr" ? aboutFr : aboutEn

    // console.log(about)
    return (
        <section className="about" data-title={about.data.title.text}>
            <h2 className="section-title xs-only">{localized["about"]}</h2>
            <div className="inner ">
                <div className="fl">
                    <div className="refs fL pt-xl pr-s pl-l">
                        <h2>Nos références</h2>
                        <div
                            className="texte "
                            dangerouslySetInnerHTML={{
                                __html: about.data.references.html,
                            }}
                        ></div>
                    </div>
                    <div className="about-content pt-xl pr-xxl pl-xxl">
                        <div
                            className="texte fM"
                            dangerouslySetInnerHTML={{
                                __html: about.data.texte.html,
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionAbout

const query = graphql`
    query {
        aboutFr: prismicAbout(lang: { eq: "fr-fr" }) {
            data {
                title {
                    text
                }
                texte {
                    html
                }
                references {
                    html
                }
            }
        }
        aboutEn: prismicAbout(lang: { eq: "en-gb" }) {
            data {
                title {
                    text
                }
                texte {
                    html
                }
                references {
                    html
                }
            }
        }
    }
`
