import React, { useState } from "react"
import PubSub from "pubsub-js"
import { useEffect } from "react"
const fleche = require("../images/fleche.svg")

const SectionTeamItem = props => {
    const { item, index } = props
    const [active, setActive] = useState(false)

    useEffect(() => {
        const token = PubSub.subscribe("SectionTeamItem", (e, d) => {
            if (d !== index) {
                setActive(false)
            }
        })

        return () => {
            PubSub.unsubscribe(token)
        }
    }, [])

    const _onClick = () => {
        const newActive = !active
        setActive(newActive)
        if (newActive) PubSub.publish("SectionTeamItem", index)
    }

    return (
        <div className={"accordion-item " + (active ? "active" : "")}>
            <div className="accordion-header" onClick={_onClick}>
                <h3 className="title tu">{item.title.text}</h3>
                <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{
                        __html: item.job.html,
                    }}
                ></div>
                <img src={fleche} alt="" />
            </div>
            <div className="accordion-content">
                <div
                    className="texte"
                    dangerouslySetInnerHTML={{
                        __html: item.texte.html,
                    }}
                ></div>
            </div>
        </div>
    )
}

export default SectionTeamItem
