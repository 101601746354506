import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProjectCard from "./ProjectCard"
import { WrapperContext } from "./Layout"

const SectionProjects = props => {
    const _WrapperContext = useContext(WrapperContext)
    const { locale, localized } = _WrapperContext
    // console.log(_WrapperContext)
    const { projectsFr, projectsEn } = useStaticQuery(query)
    const projects = locale === "fr-fr" ? projectsFr : projectsEn
    // console.log(locale)
    // console.log(projects)
    // const { current  } = props
    // console.log(current)
    return (
        <section className="projects" data-title={localized["projects"]}>
            <h2 className="section-title xs-only">{localized["projects"]}</h2>
            <div className="inner">
                <div className="fl">
                    {projects.edges.map(({ node }, i) => (
                        <ProjectCard
                            key={i}
                            index={i}
                            uid={node.uid}
                            data={node.data}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default SectionProjects

const query = graphql`
    query {
        projectsFr: allPrismicProject(
            filter: { lang: { eq: "fr-fr" } }
            sort: { fields: data___ordre }
        ) {
            edges {
                node {
                    uid
                    data {
                        ...projet
                    }
                }
            }
        }
        projectsEn: allPrismicProject(
            filter: { lang: { eq: "en-gb" } }
            sort: { fields: data___ordre }
        ) {
            edges {
                node {
                    uid
                    data {
                        ...projet
                    }
                }
            }
        }
    }
`
