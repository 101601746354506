import React from "react"
import { Texte, Images, FullImage } from "./modules"

const ProjectModules = props => {
    const { data, contentClassName } = props

    const _renderModules = modules => {
        // console.log(modules)
        const _module = modules.map((module, i) => {
            switch (module.slice_type) {
                case "module_texte":
                    return <Texte key={i} input={module} />
                case "module_images":
                    return <Images key={i} input={module} />
                case "module_full_image":
                    return <FullImage key={i} input={module} />
                default:
                    return null
            }
        })

        return _module
    }

    return (
        <div className={"project-content " + contentClassName}>
            <div className="fl">{data && _renderModules(data)}</div>
        </div>
    )
}

export default ProjectModules
