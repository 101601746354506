import React, { useEffect, useState } from 'react';
import igScrap from 'ig-scrap'

const InstagramCard = (props) => {
  const [user, setUser] = useState();
  const [media, setMedia] = useState();

  useEffect(() => {
    _fetchData()
  }, [])

  const _fetchData = () => {
    // console.log(props.username)
    // console.log(igScrap.user)
    igScrap.user(`https://www.instagram.com/${props.username}`, (err, result) => {
      // console.log(result)
      if(result){
        const {
          user,
          media
        } = result
        setUser(user)
        setMedia(media[0])
      }

      // console.log(media[0])
    })
  }

  return !media
  ? null
  : (
    <div className="instagram-card">
      <div className="media">
        <img src={media.thumbnail_resources[1].src} alt="Instagram post"/>
      </div>
      <div className="media-body">
        <div className="title">{user.full_name}</div>
        <p>{media.caption}</p>
        <a target="_blank" href={`https://www.instagram.com/${props.username}`}>⟶ @{props.username}</a>
      </div>
    </div>
  );
};

export default InstagramCard;