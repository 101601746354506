import React, { Component, Fragment } from "react"
import HorizontalScroll from "./lib/react-scroll-horizontal"
import PubSub from "pubsub-js"
import scrollIntoView from "scroll-into-view"
import {
    // throttle,
    isElementInViewport,
    is_touch_device,
} from "../core/utils"
import { throttle } from "throttle-debounce"
import SectionHome from "./SectionHome"
import SectionProjects from "./SectionProjects"
import SectionContact from "./SectionContact"
import SectionAbout from "./SectionAbout"
import SectionTeam from "./SectionTeam"
import { WrapperContext } from "./Layout"
import i18n from "../../config/i18n"

class PageScroller extends Component {
    static contextType = WrapperContext

    constructor(props) {
        super(props)
        this.state = {
            animValues: 0,
            sections: [],
            prev: null,
            prevTitle: "",
            next: null,
            nextTitle: "",
            className: "loading",
        }

        this._onScroll = throttle(900, this._onScroll)
    }
    componentWillMount() {
        // console.log("componentWillMount", window)
        this._append()
    }

    componentDidMount() {
        const { locale } = this.context

        if (window.innerWidth <= 768) {
            this._reorderSM()
        }

        this.isTouch = is_touch_device()

        if (window.location.pathname.indexOf("project") === -1) {
            const selectorScrollTo = i18n[locale].home
            this._scrollToSelector(selectorScrollTo)
        }

        this._onScroll()

        this.token = PubSub.subscribe("SCROLL_BY", (e, payload) => {
            // console.log(payload)
            if (this.isTouch) {
                scrollIntoView(payload.element, {
                    time: 500,
                    align: {
                        topOffset: 58,
                    },
                })
            } else {
                this.setState({
                    animValues: -payload.left,
                })
            }
            setTimeout(() => {
                this.setState({
                    className: "",
                })
            }, 500)
        })
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token)
    }

    _resize = () => {
        this.ww = window.innerWidth
        this.wh = window.innerHeight
    }

    _scrollTo = to => {
        const element = document.querySelector("#" + to)
        element.scrollIntoView()
    }

    _scrollToSelector = selector => {
        // console.log(selector)
        // if(selector === ".accueil")selector = ".home"
        const { animValues } = this.state
        const sections = document.querySelectorAll(
            'section[data-title="' + selector + '"]'
        )
        if (!sections) return
        // sections.forEach(el => {
        //   const {x} = el.getBoundingClientRect()
        //   const diff = animValues - x;
        //   console.log(x, animValues, diff)
        // })
        const section = sections[sections.length - 1]
        if (!section) return

        // return
        const { left } =
            section !== undefined ? section.getBoundingClientRect() : 0
        // console.log(-left, -(left + 1))
        this.setState(
            {
                animValues: -(left + 1),
            },
            () => {
                setTimeout(() => {
                    this._onScroll()
                    this.setState({
                        className: "",
                    })
                }, 500)
            }
        )
    }

    _scrollToElement = el => {
        // console.log(el)
        const { left } = el.getBoundingClientRect()
        this.setState({
            animValues: -left,
        })
    }

    _prepend = () => {
        this.setState(
            previousState => ({
                sections: [this._getSections(), ...previousState.sections],
            }),
            () => {
                this._scrollToSelector("Accueil")
            }
        )
    }

    _append = () => {
        this.setState(previousState => ({
            sections: [...previousState.sections, this._getSections()],
        }))
    }

    _reorderSM = () => {
        this.setState({
            sections: [this._getSections(true)],
        })
    }

    _getSections = (sm = false) => {
        if (sm) {
            return (
                <>
                    <SectionHome />
                    <SectionProjects />
                    <SectionAbout />
                    <SectionTeam />
                    <SectionContact />
                </>
            )
        } else {
            return (
                <>
                    <SectionContact />
                    <SectionTeam />
                    <SectionAbout />
                    <SectionHome />
                    <SectionProjects />
                </>
            )
        }
    }

    _onScroll = curr => {
        // console.log("_onScroll", curr)
        // const wrapper = document.querySelector(".scroll-wrapper").firstChild
        const sections = document.querySelectorAll("section")
        sections.forEach(el => {
            const isInView = isElementInViewport(el)
            //
            if (isInView) {
                // console.log(el.dataset.title)
                this.setState({
                    prev: el.previousSibling,
                    prevTitle:
                        el.previousSibling && curr != 0
                            ? el.previousSibling.dataset.title
                            : "",
                    next: el.nextSibling,
                    nextTitle: el.nextSibling
                        ? el.nextSibling.dataset.title
                        : "",
                })
            }
        })
    }

    _onReachEnd = () => {
        console.log("_onReachEnd")
        this._append()
    }

    render() {
        const {
            animValues,
            sections,
            prev,
            prevTitle,
            next,
            nextTitle,
            className,
        } = this.state

        return (
            <div className={"page-scroller " + className}>
                <HorizontalScroll
                    animValues={animValues}
                    pageLock={true}
                    reverseScroll={true}
                    className="scroll-wrapper"
                    config={{ stiffness: 300, damping: 40 }}
                    // onScroll        = {throttle(1000, this._onScroll)}
                    onScroll={this._onScroll}
                    onReachEnd={this._onReachEnd}
                >
                    {sections.map((section, i) => (
                        <Fragment key={i}>{section}</Fragment>
                    ))}
                </HorizontalScroll>
                <div className="prev m-only">
                    <div
                        className="inner"
                        onClick={() => {
                            this._scrollToElement(prev)
                        }}
                    >
                        {prevTitle}
                    </div>
                </div>
                <div className="next m-only">
                    <div
                        className="inner"
                        onClick={() => {
                            this._scrollToElement(next)
                        }}
                    >
                        {nextTitle}
                    </div>
                </div>
            </div>
        )
    }
}

export default PageScroller
