module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'OWL STUDIO', // Navigation and Site Title
  titleAlt: 'OWL', // Title for JSONLD
  description: '',
  headline: '', // Headline for schema.org JSONLD
  url: 'https://owl-stu.com', // Domain of your site. No trailing slash!
  image: 'https://images.prismic.io/owl-studio/1ed4ca26-3f85-43bb-8e8f-35711ffc9579_owl-image.jpg?auto=compress,format', // Used for SEO
  ogLanguage: 'fr_FR', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/images/icons/favicon.ico', // Used for manifest favicon generation
  shortName: 'aeai', // shortname for manifest. MUST be shorter than 12 characters
  author: 'aeai', // Author for schemaORGJSONLD
  themeColor: '#f9f9f9',
  backgroundColor: '#f9f9f9',

  instagram: '',
  twitter: '', // Twitter Username
  facebook: '', // Facebook Site Name
  googleAnalyticsID: '',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
