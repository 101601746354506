import React, { useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { WrapperContext } from "./Layout"
import Img from "gatsby-image"
import SectionTeamItem from "./SectionTeamItem"

const SectionTeam = () => {
    const _WrapperContext = useContext(WrapperContext)
    const { locale, localized } = _WrapperContext

    const { teamFr, teamEn } = useStaticQuery(query)
    const team = locale === "fr-fr" ? teamFr : teamEn

    return (
        <section className="team" data-title={team.data.title.text}>
            <h2 className="section-title xs-only">{localized["team"]}</h2>
            <div className="inner inner-padded">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div id="accordion" className="pr-m">
                            {team.data.persons.map((item, i) => (
                                <SectionTeamItem
                                    key={i}
                                    index={i}
                                    item={item}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="col-xs">
                        {team.data.image &&
                            team.data.image.localFile &&
                            team.data.image.localFile.fluid && (
                                <Img
                                    fluid={
                                        team.data.image.localFile
                                            .childImageSharp.fluid
                                    }
                                    alt="alt"
                                />
                            )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionTeam

const query = graphql`
    query {
        teamFr: prismicTeam(lang: { eq: "fr-fr" }) {
            data {
                title {
                    text
                }
                image {
                    url
                    localFile {
                        ...sharp
                    }
                }
                persons {
                    title {
                        text
                    }
                    job {
                        html
                    }
                    texte {
                        html
                    }
                }
            }
        }
        teamEn: prismicTeam(lang: { eq: "en-gb" }) {
            data {
                title {
                    text
                }
                image {
                    url
                    localFile {
                        ...sharp
                    }
                }
                persons {
                    title {
                        text
                    }
                    job {
                        html
                    }
                    texte {
                        html
                    }
                }
            }
        }
    }
`
