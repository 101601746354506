import React from "react";
import Img from "gatsby-image";

const FullImage = ({ input }) => {
  // console.log(input);
  return (
    <div className={"module full-image"}>
        <Img
            fluid={input.primary.image.localFile.childImageSharp.fluid}
            Tag="figure"
        />
    </div>
  );
};

export default FullImage;
