import React, { useContext } from 'react';
import { WrapperContext } from '../Layout'

const Social = () => {
    const _WrapperContext = useContext(WrapperContext)
    const { links } = _WrapperContext.options.data;

    const getHostName = url => {
        let host = ''
        if(url.indexOf("instagram") > -1)host = "instagram"
        if(url.indexOf("linkedin") > -1)host = "linkedin"
        return host
    }

    return(
        <ul className="social">
            {links.map((link,i) => (
                <li key={i}>
                    <a href={link.url.url} rel="noopener noreferrer" target="_blank">
                        <span className={`icon icon-${getHostName(link.url.url)}`}></span>
                    </a>
                </li>
            ))}
        </ul>
    )
}


export default Social;