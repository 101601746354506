import React, { useState, useEffect, useContext, useRef } from "react"
// import Img from 'gatsby-image';
import BackgroundImage from "gatsby-background-image"
import PubSub from "pubsub-js"
import website from "../../config/website"
import { getBaseUrl } from "../core/gatsby-node-helpers"
import { is_touch_device } from "../core/utils"
import { WrapperContext } from "./Layout"
import ProjectModules from "./ProjectModules"

const ProjectCard = props => {
    const delay = 500
    const projectRef = useRef()
    const cardRef = useRef()

    const _WrapperContext = useContext(WrapperContext)
    const { locale, slug } = _WrapperContext
    const { i, uid, data } = props
    const isActive = slug === uid
    let baseUrl = ""
    // console.log(slug, uid)

    // console.log("isActive",isActive)
    const [active, setActive] = useState(isActive)
    // const [contentClassName, ] = useState("");

    useEffect(() => {
        // console.log(_WrapperContext)
        baseUrl = getBaseUrl(locale)
        // console.log(baseUrl)
        const tokenClose = PubSub.subscribe("PROJECT.CLOSE", (e, d) => {
            _delayedClose()
        })

        const tokenOpen = PubSub.subscribe("PROJECT.OPEN", (e, d) => {
            if (uid !== d.uid) {
                // setActive(false)
                _delayedClose()
            }
        })
        // console.log(active)

        if (active) {
            _setWidth()
            setTimeout(() => {
                _scrollTo()
            }, delay)
        }
        // _toggle()
        return () => {
            PubSub.unsubscribe(tokenClose)
            PubSub.unsubscribe(tokenOpen)
        }
    }, [active])

    const _onClick = () => {
        // console.log("_onClick")
        // console.log(active)
        const _active = !active
        if (_active) {
            PubSub.publish("PROJECT.OPEN", { uid: props.uid })
            // _delayedOpen()
            setActive(true)
            // _setWidth()
            _setProjectUrl()
        } else {
            _delayedClose()
            _resetProjectUrl()
        }
    }

    const _setProjectUrl = () => {
        const title = props.data.title.text
        const url = baseUrl + "/project/" + props.uid

        // const localizedHomePath = locales[lang].default
        // ? '/'
        // : `/${locales[lang].path}`

        window.history.pushState({}, title, url)
    }

    const _resetProjectUrl = () => {
        window.history.pushState({}, website.title, baseUrl)
    }

    const _scrollTo = () => {
        // console.log(projectRef)
        if (!projectRef.current) return
        // const isTouch = is_touch_device()
        const _projectRef = projectRef.current
        const bounding = _projectRef.getBoundingClientRect()
        // console.log(bounding)
        const position = {
            left: bounding.left + window.pageXOffset,
            top: bounding.top,
            element: _projectRef,
        }
        PubSub.publish("SCROLL_BY", position)
    }

    // const _delayedOpen = () => {
    //     // setContentClassName("animeIn")
    //     // setTimeout(() => {
    //     //     setContentClassName("")
    //     // } , 250)
    // }

    const _delayedClose = () => {
        // setContentClassName("animeOut")
        if (!cardRef.current) return
        const cardWidth = cardRef.current.getBoundingClientRect().width
        projectRef.current.style.maxWidth = cardWidth + "px"
        setActive(false)
        // setTimeout(() => {
        //     setActive(false)
        //     // setContentClassName("")
        // } , delay)
    }

    // console.log(props.data)
    const _setWidth = () => {
        const isTouch = is_touch_device()
        if (isTouch) return

        const _projectRef = projectRef.current
        const cardWidth = cardRef.current.getBoundingClientRect().width
        // console.log(_projectRef.querySelector(".project-content"))
        setTimeout(() => {
            const width = _getModulesWidth()
            // console.log(active, width)
            _projectRef.style.maxWidth = cardWidth + width + "px"
        }, 150)
    }

    const _getModulesWidth = () => {
        let width = 0
        const _projectRef = projectRef.current
        // console.log(_projectRef)
        const content = _projectRef.lastChild
        if (content) {
            const modules = content.querySelectorAll(".module")
            // console.log(modules)
            modules.forEach(el => {
                width += el.getBoundingClientRect().width
            })
        }
        return width
    }

    const _onMouseEnter = () => {
        const { color } = props.data
        // console.log(color)
        if (color) cardRef.current.style.backgroundColor = color
    }

    const _onMouseLeave = () => {
        const { color } = props.data
        if (color) cardRef.current.style.backgroundColor = "#fff"
    }
    console.log(data.title.text)
    console.log(data.image_featured.localFile)
    // const figureClass = ""
    const figureClass =
        data.image_featured.localFile?.childImageSharp.fluid.aspectRatio < 1
            ? "is-portrait"
            : "is-landscape"

    return (
        <div
            ref={projectRef}
            className={"project " + (active ? "active" : "")}
            id={uid}
            style={
                props.data.color && {
                    backgroundColor: props.data.color,
                }
            }
            onMouseEnter={_onMouseEnter}
            onMouseLeave={_onMouseLeave}
        >
            <div
                ref={cardRef}
                className="project-card pt-xl pb-xl pr-s pl-s"
                onClick={_onClick}
                role="button"
                tabIndex={i}
            >
                <div className="card">
                    {active ? (
                        <h1 className="fL ">{data.title.text}</h1>
                    ) : (
                        <h2 className="fL ">{data.title.text}</h2>
                    )}

                    {data.image_featured.localFile && (
                        <BackgroundImage
                            fluid={
                                data.image_featured.localFile.childImageSharp
                                    .fluid
                            }
                            className={figureClass}
                            backgroundColor={
                                data.color ? data.color : "#ffffff"
                            }
                            Tag="figure"
                            // draggable={true}
                        />
                    )}
                </div>
                <div className="metas">
                    <div
                        className="texte "
                        dangerouslySetInnerHTML={{
                            __html: data.metas.html,
                        }}
                    ></div>
                </div>
            </div>

            {active && <ProjectModules data={data.body} />}
        </div>
    )
}

export default ProjectCard
