import React from "react"
import LangSwitcher from "./ui/LangSwitcher"
import Social from "./ui/Social"

const Footer = () => (
    <footer>
        <LangSwitcher />
        <Social />
    </footer>
)

export default Footer
